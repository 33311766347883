<template>
  <div
    class="bg-blue"
    style="height: 100%"
  >
    <div class="signin">
      <div class="has-text-centered">
        <img
          class="mb-4"
          src="/img/logo.svg"
          alt="Nutriweb"
          width="134"
          height="46"
        >
      </div>
      <Card content-class="px">
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit }"
          tag="div"
        >
          <form
            method="post"
            autocomplete="off"
            @submit.prevent="handleSubmit(sendRequest)"
          >
            <b-input-validation
              v-model="email"
              rules="required"
              :label="$t('email')"
              vid="email"
              input-type="email"
              :message="forgot ? message : null"
              :is-error="!!message"
              :placeholder="placeholders.user.email"
              class="pb-4"
            />
            <div class="p-relative">
              <transition
                name="fade"
                mode="in-out"
              >
                <b-input-validation
                  v-if="!forgot"
                  v-model="password"
                  rules="required"
                  :label="$t('password')"
                  vid="password"
                  input-type="password"
                  password-reveal
                  :placeholder="placeholders.user.password"
                  :message="message"
                  :is-error="!!message"
                />
              </transition>
              <span
                class="forgot"
                @click="switchForgot"
              >
                <b-icon
                  v-if="forgot"
                  icon="arrow-left"
                  size="is-small"
                />
                {{ $t(!forgot ? "forgot_password" : "back") }}
              </span>
            </div>
            <transition
              name="fade"
              mode="in-out"
            >
              <b-button
                type="is-primary"
                class="mt-4"
                :disabled="loading"
                :loading="loading"
                tag="input"
                native-type="submit"
                :value="$t(!forgot ? 'signin' : 'validate')"
              >
                {{ $t(!forgot ? "signin" : "validate") }}
              </b-button>
            </transition>
          </form>
        </ValidationObserver>
      </Card>
    </div>
  </div>
</template>

<script>
import Cookie from "js-cookie";
export default {
  data() {
    return {
      loading: false,
      email: null,
      password: null,
      message: null,
      forgot: false
    };
  },
  methods: {
    switchForgot() {
      this.$refs.observer.reset();
      this.forgot = !this.forgot;
      this.message = null;
    },
    sendRequest() {
      if (this.forgot) {
        this.forgotPassword();
      } else {
        this.signin();
      }
    },
    forgotPassword() {
      this.loading = true;
      let user = {
        email: this.email
      };

      this.$axios
        .post("password/forgot", user)
        .then(() => {
          this.notify(this.$t("request_successfully_sent"), "is-success");
          this.loading = false;
        })
        .catch(e => {
          this.loading = false;
          if (e.response && e.response.data.message) {
            const message = e.response.data.message;
            this.message = message;
          }
        });
    },
    signin() {
      this.loading = true;
      let user = {
        email: this.email,
        password: this.password
      };

      this.$axios
        .post("me/signin", user)
        .then(response => {
          let auth = response.data;
          this.$store.commit("SET_AUTH_TOKEN", auth.token);
          this.$store.commit("SET_AGENT", auth.me);
          // this.$store.commit("SET_WAREHOUSES", auth.warehouses); 
          // this.$store.commit("SET_SELECTED_WAREHOUSE", auth.warehouses[0]);
          Cookie.set("token", auth.token, { expires: auth.expires });
          Cookie.set("lang", auth.me.language ? auth.me.language.iso : "en", {
            expires: auth.expires
          });

          if (this.$route.query.to) window.location.href = this.$route.query.to;
          else window.location.href = "/";
        })
        .catch(e => {
          this.loading = false;
          if (e.response && e.response.data.message) {
            const message = e.response.data.message;
            // const code = parseInt(e.response && e.response.status);
            // if (code && code === 401)
            this.message = message;
          }
        });
    }
  }
};
</script>
